import { Service } from 'types/types'
import car from '@public/car.svg'
import pen from '@public/pen.svg'
import key from '@public/key-solid.svg'
import crash from '@public/car-crash-solid.svg'
import check from '@public/circle-check-solid.svg'
import warehouse from '@public/warehouse-solid.svg'

export const TEL_1 = {
    formatted: '0741557853',
    formatted_country_code: '+40741557853',
    raw: 'tel:+40741557853',
}
export const TEL_2 = {
    formatted: '0756033504',
    raw: 'tel:+40756033504',
}

export const EMAIL = {
    gmail: 'consiliereaccidenteauto@gmail.com',
    formatted: 'contact@consiliere-accidente-auto.ro',
    raw: 'mailto:contact@consiliere-accidente-auto.ro',
}
export const NAVIGATION = [
    { name: 'Acasa', href: '/' },
    { name: 'Despre noi', href: '/despre-noi' },
    { name: 'Servicii', href: '/servicii' },
    { name: 'Contact', href: '/contact' },
]

export const SITE_NAME = 'Consiliere accidente auto'

export const GA_MEASUREMENT_ID = 'G-8DQ4ZEYFGJ'

export const SERVICES: Service[] = [
    {
        name: 'Consiliere Daune Auto',
        slug: '/servicii/consiliere-daune-auto',
        figcaption: 'Consiliere Daune Auto',
        icon: car,
        imageUrl:
            'https://images.unsplash.com/photo-1516321318423-f06f85e504b3?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=20',

        description: `
Echipa noastra ofera asistenta profesionala in rezolvarea daunelor auto si nu numai.
Oferim asistenta persoanelor fizice si juridice, parti pagubite sau vinovate ale unui eveniment rutier.

Specialistii nostri preiau cazul si ofera consultanta de la momentul evenimentului rutier: stabilirea vinovatiei conform legislatiei rutiere actuale, completarea Constatului Amiabil sau insotirea la Serviciul Rutier in vederea eliberarii Procesului verbal si al Autorizatiei de reparatie.
Continuam prin a Deschide Dosarul de dauna la asigurator si recuperarea prejudiciului.

Prin contractele de colaborarea pe care le avem cu Atelierele de reparatie autorizate RAR ne asiguram ca autoturismul este reparat in cele mai bune conditii.

In cazul in care pagubitul nu doreste reparatia sau autoturismul este declarat Dauna Totala ne asiguram ca despagubirea stabilita de asigurator este cea corecta din punct de vedere financiar si pagubitul intra cat mai repede in posesia despagubirii.`,
    },
    {
        name: 'Devize de reparatie si V.M.D.',
        slug: '/servicii/devize-de-reparatie-si-vmd',
        figcaption: 'constatare',
        icon: pen,
        imageUrl:
            'https://images.unsplash.com/photo-1450101499163-c8848c66ca85?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=20',
        description: `
Prin programele specializate ( D.A.T. si AUDATEX ) recunoscute la nivel European de catre asiguratori si prin experienta de peste 15 ani in efectuarea calculatiilor de reparatie, punem la dispozitia persoanelor fizice si juridice calculatii conform Notelor de Constatare si Reconstatare emise de asigurator.

Prin acest serviciu pe care il punem la dispozitie atelierelor autorizate RAR in vederea reparatiilor autoturismelor acestea pot factura catre asigurator contravaloarea reparatiei.

Totodata si persoanele fizice pot beneficia de aceste calculatii pentru verificarea despagubirii oferite de asigurator sau pentru cazurile in care este necesara infatisarea in Instantele Judecatoresti.

Tot prin intermediul acestor platforme punem la dispozitie si Calculul Valorii Maxime de despagubire in cazul Daunelor Totale sau verificarii valorii economice a autoturismului conform seriei de sasiu, a kilometrilor si a vechimii acestuia.
Prin acest serviciu se poate stabili valoarea corecta a autoturismului.
`,
    },
    {
        name: 'Inchirieri Auto',
        slug: '/servicii/inchirieri-auto',
        figcaption: 'inchiriere auto',
        icon: key,
        imageUrl:
            'https://images.unsplash.com/photo-1565043666747-69f6646db940?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=500&h=300&q=20',
        description: `
Serviciul de inchiriere auto de care dispunem ne ajuta sa oferim clientilor autoturisme calitative cu Casco si Rovineta.

Autoturismele sunt predate clientilor pe toata durata reparatiei ,in cazul reparatiilor cu decontare de la asigurator.

Clientii nu sunt nevoiti sa isi faca griji ca asiguratorul deconteaza mai putine zile decat sunt necesare reparatiei, acestia beneficiaza de autoturism pana la finalizarea reparatiei, iar costul de inchiriere este suportat de asigurator .`,
    },
    {
        name: 'Reparatii, Comercializare de piese si Tractatori Auto',
        slug: '/servicii/reparatii-comercializare-de-piese-si-tractatori-auto',
        figcaption: 'reparatii',
        icon: crash,
        imageUrl: '/reparatii.webp',
        description: `
Prin colaborarea cu Atelierele de reparatie va punem la dispozitie serviciul de asistenta pe durata reparatiei care consta in urmatoarele beneficii:
• Deplasarea autoturismului avariat in unitatea reparatorie, clientul nu mai este nevoit sa se deplaseze doar ne imputerniceste prin procura notariala sa il reprezentam in relatia cu service-ul;
• Verificam si supraveghem continuu stadiul reparatiei si tinem legatura cu clientul pentru a-i furniza detalii;
• Ne asiguram ca reparatia este conforma atat din punct de vedere estetic cat si tehnic;
• La momentul in care luam autoturismul avariat ne ingrijim sa lasam autoturismul de schimb clientului pentru a avea cu ce se deplasa;
• La finalul reparatiei tot agentii nostri se vor ocupa de deplasarea la domiciliul clientului cu autoturismul reparat si predarea acestuia.
Pentru clientii care doresc repararea autoturismului in regie proprie ne deplasam la domiciliul clientului sau in locul indicat de acesta si evaluam valoarea reparatiei
La cererea clientului putem oferta piese auto atat (AM / OE)
Ne pliem pe dorintele clientului in asa fel incat sa ii satisfacem nevoile atat din punct de vedere tehnic cat si financiar
Cu sprijinul colaboratorilor nostri, putem asigura serviciul de tractare la cererea clientului din orice zona si la orice ora.
Cu ajutorul specialistilor nostri va putem indruma cum sa va recuperati integral costul tractarii de la asiguratorul vinovatului.`,
    },
    {
        name: 'Asigurari RCA si CASCO',
        slug: '/servicii/asigurari-rca-si-casco',
        figcaption: 'rca si casco',
        icon: check,
        imageUrl:
            'https://images.unsplash.com/photo-1633158829585-23ba8f7c8caf?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1740&q=30',
        description: `
Datorita colaborarii cu Brokerii de Asigurari va putem oferi preturi competitive atat la politele RCA cat si la Politele Casco.
Consultantii nostri va vor oferi sprijin in alegerea politei mai avantajoase pentru dumneavoastra. Brokerii de asigurari cu care colaboram va pot oferi polite in orice domeniu de asigurare atat auto cat si non-auto.`,
    },
    {
        name: 'Inspectii Tehnice Periodice',
        figcaption: 'ITP',
        slug: '/servicii/itp',
        imageUrl: '/statie-itp.jpeg',
        icon: warehouse,
        description: `La solicitarea clientului va putem inregistra in baza noastra de date si tine evidenta Inspectiilor tehnice si va putem anunta inainte de expirarea acesteia. Daca se doreste ne putem ocupa cu deplasarea la statia de ITP .`,
    },
]
