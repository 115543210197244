import { useRouter } from 'next/router'
import '../styles/globals.scss'
import type { AppProps } from 'next/app'
import { Header, Footer } from '@components/section'
import Script from 'next/script'
import { GA_MEASUREMENT_ID } from '@utils/constants'

function MyApp({ Component, pageProps }: AppProps) {
    const router = useRouter()
    return (
        <>
            {router.pathname !== '/' && <Header />}
            <Component {...pageProps} />
            <Footer />

            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${GA_MEASUREMENT_ID}`}
                strategy="afterInteractive"
            />

            <Script id="google-analytics" strategy="afterInteractive">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){window.dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${GA_MEASUREMENT_ID}');
                `}
            </Script>
        </>
    )
}

export default MyApp
