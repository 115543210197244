import dynamic from 'next/dynamic'

export const CTA = dynamic(() => import('./CTA'), { ssr: true })
export const FAQs = dynamic(() => import('./FAQs'), { ssr: true })
export const Features = dynamic(() => import('./Features'), { ssr: true })
export const Footer = dynamic(() => import('./Footer'), { ssr: true })
export const Header = dynamic(() => import('./Header'), { ssr: true })
export const Hero = dynamic(() => import('./Hero'), { ssr: true })
export const Newsletter = dynamic(() => import('./Newsletter'), { ssr: true })
export const Testimonial = dynamic(() => import('./testimonials/Testimonial'), {
    ssr: true,
})
export const Testimonials = dynamic(() => import('./testimonials/Testimonials'), {
    ssr: true,
})
